/* eslint-disable react/prop-types */
import debounce from 'debounce-promise'
import merge from 'deepmerge'
import { getIn } from 'formik'
import React, { Component, useState, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import Select, { OptionProps, GroupHeadingProps, components } from 'react-select'
import AsyncSelect from 'react-select/async'

import { FormSelectProps } from './FormSelect.types'
import { MoreSearchOptionsIcon } from '../../icons'
import { OptionType, Suburb } from '../../Search/Search.types'
import useCustomCompareMemo from '../../utils/useCustomCompareMemo'


const styleProxy = new Proxy({}, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get: () => () => {}
})

class CustomOption extends Component {
  render() {
    const selectOption = getIn(this.props, 'selectOption')
    // @ts-ignore
    return <components.Option
      {...this.props}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      selectOption={() => {}}
      isFocused={false}
    >
      <div className="customopt">
        <label className="checkcontainer">
          <input
            type="checkbox"
            checked={getIn(this.props, 'isSelected')}
            onChange={() => selectOption(getIn(this.props, 'value'))}
          />
          <span className={'checkmark'}>
            {getIn(this.props, 'isSelected') && getIn(this.props, 'isSelected') !== 'false' ? <svg viewBox="0 0 17 13"><use href="#icon16-Check-Small" /></svg> : null}
          </span>
        </label>
        {getIn(this.props, 'label')}
      </div>
    </components.Option>
  }
}

interface NestedGroupProps extends OptionProps<OptionType<string>, boolean> {
  headingProps: GroupHeadingProps<OptionType<string>>,
  id: unknown,
  label: string,
  options: OptionType<string>[]
  data: OptionType<string>
}

const renderNestedOption = (props: NestedGroupProps) => {
  const {
    headingProps,
    innerProps,
    data,
    selectOption
  } = props
  const nestedOptions = data.options || []
  const children = nestedOptions.map((nestedOption: OptionType<string>) => {
    if (nestedOption.options) {
      return renderNestedOption({
        ...props,
        data: nestedOption,
        innerProps: { ...innerProps, onClick: null }
      })
    }

    const nestedInnerProps = {
      ...innerProps,
      onClick: () => {
        selectOption(nestedOption)
      }
    }
    let isSelected = false
    const value = props.selectProps.value
    if (props.isMulti && Array.isArray(value)) {
      if (nestedOption.area && value
        .map(x => x.value).includes(nestedOption.area)) {
        isSelected = true
      } else if (
        nestedOption.province
        && value.map(x => x.province).length
        && !value
          .map(x => x.province).includes(nestedOption.province)) {
        isSelected = true
      } else {
        isSelected = value.map(x => x.value).includes(nestedOption.value)
      }
    } else {
      isSelected = props.selectProps.value as unknown as keyof OptionType<string> === nestedOption.value
    }
    if (isSelected) { return null }
    return (
      <div className="nested-optgroup-option" key={nestedOption.value}>
        {
          (
            <components.Option
              {...{
                ...props,
                label: nestedOption.label,
                value: nestedOption.value,
                isSelected,
                data: nestedOption
              }}
              // @ts-ignore
              innerProps={nestedInnerProps}>
              {nestedOption.label}
            </components.Option>
          ) as React.ReactNode
        }
      </div>
    )
  }).filter(child => child)
  // Will be applied to nested optgroup headers
  const groupProps = {
    ...props,
    label: data.label,
    options: nestedOptions,
    headingProps: headingProps ? headingProps : { id: innerProps.id, data }
  }

  if (!children.length) {
    // @ts-ignore
    return null
  }
  // @ts-ignore
  return <components.Group key={data.label} {...groupProps}>{children}</components.Group>
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Group = (props: any) => renderNestedOption({
  ...props
})


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MoreSearchOptionsIcon />
  </components.DropdownIndicator>
)


const filterOptions = (
  options: OptionType<string>[],
  inputValue: string,
  value: OptionType<string>[] | OptionType<string>
) : OptionType<string>[] | null => {
  if (inputValue) {
    const newOptions = options.map(o => {
      if (o.options) {
        const has_value = filterOptions(o.options, inputValue, value)
        return has_value.length ? {
          ...o,
          options: filterOptions(o.options, inputValue, value)
        } : null
      }
      if (o.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
        return merge({}, o)
      }
      return null
    }).filter(o => o)
    // @ts-ignore
    return newOptions
  }
  return options
}

export const parseOptions = (
  options: Suburb[] | [],
  list: Suburb[] = []
) => {
  for (const option of options) {
    if (option.options) {
      parseOptions(option.options, list)
    } else {
      list.push(option)
    }
  }
  return list
}

export function FormSelect<Form>(props: FormSelectProps<Form>): JSX.Element {
  const { field, formikProps, options, placeholder, components: comps, isMulti, isChecks, ...rest } = props
  const [ flatOptions, setFlatOptions ] = useState<OptionType<string>[]>([])
  const [ filteredOptions, setFilteredOptions ] = useState(options)
  const [ inputValue, setInputValue ] = useState('')
  // @ts-ignore
  useEffect(() => setFlatOptions(parseOptions(options)), [ options ])

  const values = flatOptions.filter(x => {
    if (isMulti && Array.isArray(getIn(formikProps.values, field.toString()))) {
      return getIn(formikProps.values, field.toString()).includes(x.value)
    }
    return isEqual(
      x.value,
      getIn(formikProps.values, field.toString())
    )
  })

  function handleInputChange(val: string, e: { action: string }) {
    if (e.action === 'input-change') {
      setInputValue(val)
    }
  }

  const hideSelectedOptions = ![
    null,
    undefined
  ].includes(props.hideSelectedOptions) ? props.hideSelectedOptions : !isChecks

  const value = isMulti ? values : values[0]

  useEffect(() => {
    let newOptions
    if (rest.filterOptions) {
      newOptions = rest.filterOptions(options, inputValue, value)
    } else {
      newOptions = filterOptions(options, inputValue, value)
    }
    // @ts-ignore
    setFilteredOptions(newOptions)
  }, [ inputValue, useCustomCompareMemo(options), useCustomCompareMemo(value) ])

  const SelectComponent = props.loadOptions ? AsyncSelect : Select

  return <SelectComponent
    placeholder={isChecks && isMulti ? `${values.length} Selected` : placeholder}
    defaultValue={value}
    value={value}
    className={'react-select'}
    classNamePrefix="react-select"
    onChange={v => {
      if (isMulti) {
        formikProps.setFieldValue(
          field.toString(),
          // @ts-ignore
          v && Array.isArray(v) ? v.map((x: { value: string }) => x.value) : undefined
        )
        formikProps.setFieldTouched(field.toString())
      } else {
        // @ts-ignore
        formikProps.setFieldValue(field.toString(), v ? v.value : undefined)
        formikProps.setFieldTouched(field.toString())
      }
    }}
    filterOption={() => true}
    onInputChange={handleInputChange}
    onMenuClose={() => setInputValue('')}
    closeMenuOnSelect={!isChecks}
    hideSelectedOptions={hideSelectedOptions}
    controlShouldRenderValue={!isChecks}
    inputValue={inputValue}
    options={filteredOptions}
    isMulti={isMulti}
    isSearchable={props.isMulti ? true : false}
    styles={styleProxy}
    loadOptions={props.loadOptions ? debounce(props.loadOptions, 500) : null}
    noOptionsMessage={props.noOptionsMessage ? props.noOptionsMessage : () => null}
    components={{
      Group,
      DropdownIndicator,
      Option: isChecks ? CustomOption : components.Option,
      ...comps
    }}
    {...rest}
  />
}
