import React, { useState } from 'react'
import Select, { components } from 'react-select'

import { MoreSearchOptionsIcon } from '../icons'
import { OptionType } from '../Search/Search.types'


const styleProxy = new Proxy({}, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get: () => () => {}
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MoreSearchOptionsIcon />
  </components.DropdownIndicator>
)

const sortOptions = window.SORT_OPTIONS

const searchParams = new URLSearchParams(window.location.search)

let sortValue
window.setSortValue = v => {sortValue = v}

const Sort = () => {
  let defaultValue
  if (searchParams.get('s')) {
    // @ts-ignore
    defaultValue = sortOptions.find((o: OptionType<string>) => o.value && o.value === searchParams.get('s'))
  }
  // @ts-ignore
  const [ value, setValue ] = useState<OptionType<string>>(defaultValue || sortValue)
  window.setSortValue = setValue
  return (
    <Select
      defaultValue={value}
      value={value}
      className={'react-select'}
      placeholder={<div dangerouslySetInnerHTML={{ __html: window.SORT_PLACEHOLDER ? window.SORT_PLACEHOLDER : 'Sort By...' }} />}
      classNamePrefix="react-select"
      onChange={v => {
        if (v && v.value) {
          setValue(v)
          searchParams.set('s', v.value)
          if (window.SORT_CHANGE) {
            // eslint-disable-next-line new-cap
            window.SORT_CHANGE(v)
          } else {
            window.location.search = searchParams.toString()
          }
        }
      }}
      options={sortOptions}
      controlShouldRenderValue={!window.request.is_mobile || window.SORT_SHOW_VALUE}
      isSearchable={window.SORT_SEARCHABLE}
      styles={styleProxy}
      components={{
        DropdownIndicator
      }}
    />
  )
}

export default Sort
