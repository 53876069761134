import { Field, FieldArray, ArrayHelpers } from 'formik'
import React, { useEffect, useState } from 'react'

import Check from '../Check/Check'
import { Suburb } from '../Search/Search.types'
import { SearchContextType } from '../Search/SearchContext.types'
import { uniqueArray } from '../utils/uniqueArray'
import useCustomCompareMemo from '../utils/useCustomCompareMemo'


const NeighbouringSuburbs = (props: SearchContextType): JSX.Element | null => {
  const { formikProps, flatLocations } = props
  const { values } = formikProps
  const { locations, additional_locations } = values

  const [ options, setOptions ] = useState([])

  useEffect(() => {
    const filterLocations = () => {
      // Filter locations
      const suburbs = locations ? locations.filter(location => location.indexOf('s_') !== -1) : []
      const areas = uniqueArray(flatLocations
        .filter((loc: { id: string }) => suburbs.includes(loc.id))
        .map((loc: { area_id: string }) => `a_${loc.area_id}`))
      // If a whole area is selected, ignore that
      if ((!suburbs.length) || !areas.length) {
        setOptions([])
        return
      }
      const neighbouring = flatLocations
        .filter((loc: { area_id: string; id: string }) => (
          areas.includes(loc.area_id) && !suburbs.includes(loc.id)
        ))

      setOptions(neighbouring)
    }
    filterLocations()
  }, [ useCustomCompareMemo(locations) ])

  if (!(options && options.length)) { return null }

  const allAreSelected = additional_locations ? options.length === additional_locations.length : false
  const selectAll = () => {
    const optionsToSelect = allAreSelected ? [] : options
    formikProps.setValues(prevState => ({
      ...prevState,
      additional_locations: optionsToSelect
    }))
  }
  const deselectAll = () => {
    formikProps.setValues(prevState => ({
      ...prevState,
      additional_locations: []
    }))
  }

  return <div className="advanced-search-suburbs">
    <label>Include properties in neighbouring suburbs{!allAreSelected ? (<>&nbsp;|&nbsp;<div className="select-all" onClick={selectAll} style={{ cursor: 'pointer' }}>{!allAreSelected ? 'Select All' : null}</div></>) : null}{additional_locations && additional_locations.length ? (<>&nbsp;|&nbsp;<div className="unselect-all" onClick={deselectAll} style={{ cursor: 'pointer' }}>{(additional_locations && additional_locations.length) ? 'Deselect All' : null}</div></>) : null}</label>
    <div style={{ marginTop: 15 }}>
      <FieldArray
        name="additional_locations"
        render={(arrayHelpers: ArrayHelpers) => options.map(
          (option: Suburb) =>
            <Field
              key={`ns-${option.id}`}
              name="additional_locations"
              component={Check}
              label={option.suburb}
              isMulti
              value={option.id}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  arrayHelpers.push(option.id)
                } else {
                  // @ts-ignore
                  const idx = additional_locations.indexOf(option.id)
                  arrayHelpers.remove(idx)
                }
              }}
            />)}
      />
    </div>
  </div>
}


export default NeighbouringSuburbs
