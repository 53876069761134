class UrlParser {
  private readonly regex: RegExp

  private matchedValues?: RegExpExecArray

  private queryParams?: {
        [key: string]: string
    }

  constructor(
        // eslint-disable-next-line no-unused-vars
        private url: string
  ) {
    // eslint-disable-next-line no-useless-escape
    const regex = '\/results\/((?<sub>(\\w|-|\\s)+)\/?)?((?<record_id>(\\d)+)\/?)?((?<listing_type>(\\w|-|\\s)+)\/?)?((?<area>(\\w|-|\\s)+)\/?)?((?<suburb>(\\w|-|\\s)+)\/?)?((?<property_type>(\\w|-|\\s)+)\/?)?((?<establishment>(\\w|-|\\s)+)\/?)?$'
    this.regex = new RegExp(regex, 'gm')
  }

  get parsedValues() : RegExpExecArray {
    const matchedValues: RegExpExecArray = this.regex.exec(this.url.split('?')[0])
    if (matchedValues && matchedValues.length) {
      this.matchedValues = matchedValues
    }

    return this.matchedValues
  }

  get queryData() {
    if (!this.queryParams) {
      const searchParams = new URLSearchParams(this.url)
      const entries = [ ...searchParams.entries() ]

      if (entries[0] && entries[0][0]) {
        entries[0][0] = entries[0][0].slice(entries[0][0].indexOf('?') + 1)
      }

      let queryParams: {
                [key: string]: string
            } = {}

      for (const key in entries) {
        if (key) {
          const keyword = entries[key][0]
          const value = entries[key][1]

          queryParams = {
            ...queryParams,
            [keyword]: value
          }
        }
      }

      this.queryParams = queryParams
    }

    return this.queryParams
  }
}

export default UrlParser
