import React from 'react'

import { FormSelect } from './FormSelect/FormSelect'
import { FormSelectProps } from './FormSelect/FormSelect.types'
import { LocationSelect } from './LocationSelect'
import { SearchForm } from '../Search/Search.types'
// import { StylesConfig } from "react-select";

const SearchFormSelect = (props: FormSelectProps<SearchForm>) => (
  window.request.search_locations && props.field === 'locations' ? <LocationSelect {...props} /> : <FormSelect {...props} />
)


export default SearchFormSelect
