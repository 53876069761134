import debounce from 'debounce-promise'
import { getIn } from 'formik'
import React, { ReactNode } from 'react'
import { components, OptionProps, GroupBase } from 'react-select'
import AsyncSelect from 'react-select/async'

import { FormSelectProps } from './FormSelect/FormSelect.types'
import { MoreSearchOptionsIcon } from '../icons'
import { OptionType, Suburb } from '../Search/Search.types'
import { uniqueArray } from '../utils/uniqueArray'

import type {} from 'react-select/base'
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom property 'myCustomProp' to it.

declare module 'react-select/base' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    truncate?: number
    icon?: ReactNode;
  }
}

const styleProxy = new Proxy({}, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get: () => () => {}
})

const boldMatchCharacters = ({ sentence = '', characters = '' }) => {
  const regEx = new RegExp(characters, 'gi')
  return sentence.replace(regEx, '<b>$&</b>') as string
}

interface LocationOption {
  id?: number
  type: string
  value: string
  label: string
  name: string
  __isNew__?: boolean
}

const CustomOption = (props: OptionProps<LocationOption>) => {
  const label = props.data.__isNew__ ? `Search ${props.data.value}`
    : boldMatchCharacters({
      sentence: getIn(props, 'data.name'),
      characters: props.selectProps.inputValue
    })
  return <components.Option
    {...props}
  >
    <div className="search-option">
      <div
        className="search-option__label"
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <div className="search-option__type">
        {getIn(props, 'data.type')}
      </div>
    </div>
  </components.Option>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MoreSearchOptionsIcon />
  </components.DropdownIndicator>
)

export const parseOptions = (
  options: (OptionType<string> | Suburb)[] | [],
  list: (OptionType<string> | Suburb)[] = []
) => {
  for (const option of options) {
    if (option.options) {
      parseOptions(option.options, list)
    } else {
      list.push(option)
    }
  }
  return list
}

export function LocationSelect<Form>({
  field,
  formikProps: form,
  placeholder,
  components: comps,
  isMulti,
  isChecks,
  loadOptions,
  noOptionsMessage,
  ...props
}: FormSelectProps<Form>): React.ReactNode {
  const hideSelectedOptions = ![
    null,
    undefined
  ].includes(props.hideSelectedOptions) ? props.hideSelectedOptions : !isChecks

  const value = getIn(form.values, field)
  return (
    <AsyncSelect
      placeholder={placeholder}
      defaultValue={value}
      value={value}
      className={'react-select'}
      classNamePrefix="react-select"
      isClearable
      onChange={v => {
        if (isMulti) {
          form.setFieldValue(field, v)
          form.setFieldTouched(field)
        } else {
        // @ts-ignore
          form.setFieldValue(field, v ? v.value : undefined)
          form.setFieldTouched(field)
        }
      }}
      cacheOptions
      noOptionsMessage={noOptionsMessage ? noOptionsMessage : ({ inputValue }) => (inputValue ? `Sorry, we couldn't find any locations matching "${inputValue}"` : null)}
      closeMenuOnSelect={false}
      hideSelectedOptions={hideSelectedOptions}
      isMulti={true}
      loadOptions={debounce(loadOptions, 500)}
      isSearchable={true}
      styles={styleProxy}
      components={{
        DropdownIndicator,
        Option: CustomOption,
        ...comps
      }}
    />
  )
}
