import React, { useState, useEffect } from 'react'
import { Field, getIn, connect, FormikProps } from 'formik'
import SearchFormSelect from '../selects/SearchFormSelect'
import { SearchForm } from '../Search/Search.types'
import Button from '../Button/Button'
import { SearchIcon } from '../icons'
import { OptionType } from '../Search/Search.types'


const minBedsOptions: OptionType<number>[] = [
  { value: '0', label: 'Any' },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' }
]
/*
display_min_price
display_max_price
display_size_from
display_size_to
display_beds
on_show
flatlet
pets_allowed
furnished
*/
const categoryTypeOptions = {
  'For Sale': {
    Residential: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      display_beds: true
    },
    Commercial: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true
    },
    'Residential Vacant Land': {
      display_min_price: true,
      display_max_price: true
    },
    'Commercial & Industrial Vacant Land': {
      display_min_price: true,
      display_max_price: true
    },
    'Farms & Small Holdings': {
      display_min_price: true,
      display_max_price: true
    },
    Industrial: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true
    },
    Retail: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true
    },
    'Mixed Use': {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true
    },
    Agricultural: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true
    },
    'Residential Estate': {
      display_min_price: true
    },
    'Residential Development': {
      display_min_price: true
    },
    'Commercial Estate': {
      display_min_price: true
    },
    'Commercial & Industrial Development': {
      display_min_price: true
    }
  },
  'To Let': {
    Residential: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      display_beds: true
    },
    Commercial: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true
    },
    'Residential Vacant Land': {
      display_size_from: true,
      display_size_to: true
    },
    'Commercial & Industrial Vacant Land': {
      display_size_from: true,
      display_size_to: true
    },
    'Farms & Small Holdings': {
      display_min_price: true,
      display_max_price: true
    },
    Industrial: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true
    },
    Retail: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true
    },
    'Mixed Use': {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true
    },
    Agricultural: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true
    },
    'Holiday Accommodation': {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      display_beds: true
    }
  }
}


const MiniSearchForm = ({
  formik = null,
  propertyCount,
  selectedPropertyTypes
}: {
  formik?: FormikProps<SearchForm>,
  propertyCount?: number,
  selectedPropertyTypes?: OptionType<string>[],
}): React.ReactNode => {
  const {
    buy_rent,
    category
  } = formik.initialValues
  const [ typeOptions, setTypeOptions ] = useState(getIn(categoryTypeOptions, `${buy_rent}.${category}`))
  useEffect(() => {
    if (![ 'branch', 'agent', 'sold', 'rented', 'sold-rented' ].includes(getIn(window.request.params, 'sub'))) {
      setTypeOptions(getIn(categoryTypeOptions, `${buy_rent}.${category}`))
    }
  }, [ `${buy_rent}.${category}` ])

  useEffect(() => {
    if ([ 'branch', 'agent', 'sold', 'rented', 'sold-rented' ].includes(getIn(window.request.params, 'sub'))) {
      setTypeOptions(null)
    }
  }, [ window.request.params ])

  function handleNavbarAppearance() {
    const header = document.querySelector('header .wrapper')
    const navbar = document.querySelector('.sticky-property-search-filter-nav')

    const scrollPosition = window.scrollY
    const start = header ? (header.getBoundingClientRect().top) : 120
    if (scrollPosition - start > 0 && navbar?.classList.contains('hidden')) {
      navbar.classList.remove('hidden')
    }

    if (scrollPosition - start <= 0 && !navbar?.classList.contains('hidden')) {
      navbar?.classList.add('hidden')
    }
  }

  const el = React.useRef(null)


  useEffect(() => {
    if (el.current) {
      document.addEventListener('scroll', handleNavbarAppearance)
    }

    return () => {
      document.removeEventListener('scroll', handleNavbarAppearance)
    }
  }, [ el.current ])

  let pricesLabels
  if (category === 'Holiday Accommodation') {
    pricesLabels = [ 'Rates From (Per Night)', 'Rates To (Per Night)' ]
  } else if (buy_rent === 'For Sale') {
    pricesLabels = [ 'Price From', 'Price To' ]
  } else {
    pricesLabels = [ 'Rental From', 'Rental To' ]
  }

  if (!typeOptions) { return <></> }
  const image = document.querySelector('link[rel=\'icon\']') || { getAttribute: () => '' }
  const imageSrc = image.getAttribute('href').replace(/_t_w_(\d+)_h_(\d+)/gi, '')
  return (
    <div ref={el} className="top-search-container centered-container">
      <div className="advanced-search-content">
        <div className="advanced-search-fields">
          <a href="/" className='flex-column sticky-logo'>
            <img width={90} height={90} src={imageSrc ? imageSrc : ''} />
          </a>
          <div className="advanced-search-filters">
            {typeOptions?.display_property_types ? (
              <div className="flex-column type-filter">
                <span className="input-label">Property Types</span>
                <SearchFormSelect
                  field={'property_types'}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  formikProps={formik}
                  placeholder="Any"
                  options={selectedPropertyTypes}
                  isMulti
                  isChecks
                />
              </div>
            ) : null}

            {typeOptions?.display_min_price ? (
              <div className="flex-column criteria-filter">
                <span className="input-label">{pricesLabels[0]}</span>
                <Field
                  type={'number'}
                  name={'min_price'}
                  placeholder={'e.g. 500000'}
                  className={'input-field'}
                />
              </div>
            ) : null}

            {typeOptions?.display_max_price ? (
              <div className="flex-column criteria-filter">
                <span className="input-label">{pricesLabels[1]}</span>
                <Field
                  type={'number'}
                  name={'max_price'}
                  placeholder={'e.g. 1000000'}
                  className={'input-field'}
                />
              </div>
            ) : null}

            {typeOptions?.display_size_from ? (
              <div className="flex-column criteria-filter">
                <span className="input-label">Size From (m²)</span>
                <Field
                  type={'number'}
                  name={'size_from'}
                  placeholder={'e.g. 500'}
                  className={'input-field'}
                />
              </div>
            ) : null}

            {typeOptions?.display_size_to ? (
              <div className="flex-column criteria-filter">
                <span className="input-label">Size To (m²)</span>
                <Field
                  type={'number'}
                  name={'size_to'}
                  placeholder={'e.g. 1000'}
                  className={'input-field'}
                />
              </div>
            ) : null}


            {typeOptions?.display_beds ? (
              <div className="flex-column min-beds">
                <span className="input-label">{category === 'Holiday Accommodation' ? 'Min Sleeps' : 'Min. Beds'}</span>
                <SearchFormSelect
                  field={'min_beds'}
                  formikProps={formik}
                  options={minBedsOptions}
                />
              </div>
            ) : null}
            <div className="flex-column">
              <span className="input-label">&nbsp;</span>
              <div className="advanced-search-buttons">
                <div>
                  <b className="property-counter">{propertyCount} properties</b>
                </div>
                <Button
                  variant={propertyCount ? 'primary' : 'disabled'}
                  onClick={() => {
                    if (propertyCount) {
                      formik.handleSubmit()
                    }
                  }}>
                  <SearchIcon/>
                SEARCH
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MiniSearch = connect(MiniSearchForm)
